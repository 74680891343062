<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <edit-profile-form :user="user" @update="startProfileUpdate"></edit-profile-form>
        </div>
        <div class="col-md-4">
          <user-card :user="user"></user-card>
        </div>
      </div>
    </div>
    <modal name="password" class="modal-password">
      <enyo-card>
        <h4 slot="header" class="card-title">{{ $t('common.labels.password') }}</h4>
        <form @submit.prevent="updateProfile()">
          <div class="row">
            <div class="col-md-12">
              <fg-input
                type="password"
                :label="$t('common.field.password')"
                :placeholder="$t('common.placeholders.password')"
                v-model="user.currentPassword"
              ></fg-input>
            </div>
          </div>
          <div class="text-center">
            <button type="submit" class="btn btn-info btn-fill float-right" :disabled="!user.currentPassword">
              {{ $t('common.buttons.confirm') }}
            </button>
          </div>
        </form>
      </enyo-card>
    </modal>
  </div>
</template>
<script>
import { apiErrorsMixin } from 'vue-aw-components';
import { intersection } from 'lodash';
import config from '@/config';
import EditProfileForm from './user-profile/ProfileForm.vue';
import UserCard from './user-profile/UserCard.vue';

export default {
  components: {
    EditProfileForm,
    UserCard,
  },
  data() {
    return {
      user: {},
      roles: ['ADMIN', 'DEVELOPER', 'USER', 'DEMO'],
    };
  },
  mixins: [apiErrorsMixin],
  created() {
    this.loadData();
    window.comp = this;
  },
  methods: {
    loadData() {
      this.$http
        .get('/api/auth/user?isBackOfficeMode=true')
        .then((res) => {
          if (res && res.data && res.data.user) {
            this.user = res.data.user;
            this.$store.commit('user', res.data.user);
            this.$store.commit('currentLocale', res.data.user.locale || config.defaultLocale);
            if (res.data.token) {
              this.$store.commit('token', res.data.token);
            }
          }
        })
        .catch((err) => {
          console.warn('get error', err.response.status);
          this.apiErrorCallback(err);

          if (err.response && err.response.status === 404) {
            this.$store.dispatch('logout');
            this.$router.push('/login');
          }
        });
    },
    startProfileUpdate() {
      const { user } = this;

      if (user && user.password) {
        if (user.password === user.confirmPassword) {
          this.$modal.show('password');
          return;
        }

        return this.$notify({
          title: this.$t('common.messages.passwordsDoNotMatch'),
          type: 'warning',
        });
      }

      return this.updateProfile(user);
    },
    updateProfile() {
      const { user } = this;

      if (user) {
        delete user.confirmPassword;
      }

      this.$http
        .put(`/api/user/${this.user.id}`, user)
        .then((res) => {
          if (res && res.data && res.data.user) {
            this.$notify({
              title: this.$t('common.messages.successfullyUpdated'),
              type: 'success',
            });
            if (this.user.id === this.$store.state.user.id) {
              if (intersection(res.data.user.roles, this.$store.state.user.roles).length !== this.$store.state.user.roles.length) {
                this.$notify(this.$t('app.messages.user_roles_have_changed'));
              }

              this.$i18n.locale = this.user.locale;

              this.$store.commit('user', this.user);
              this.$store.commit('currentLocale', this.user.locale);
            }
            this.user = res.data.user;

            this.$modal.hide('password');
          }
        })
        .catch(this.apiErrorCallback);
    },
  },
};
</script>
<style>
.modal-password .vm--modal {
  height: unset !important;
}

.modal-password .vm--modal .card {
  margin-bottom: 0;
}
</style>
